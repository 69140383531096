import { sortBy } from 'lodash';
import {
    ganttColumnDurationTemplate,
    ganttColumnEndDateTemplate,
    ganttColumnStartDateTemplate,
} from './gantt_templates';
import DefaultUnifiedFieldId from '../../constants/DefaultPlanningField';
import { getQuantityUnitName, getYieldUnit } from './gantt_utils';
import { ALL_JOBS, JOB_FIELD } from '../../constants/Jobs';
import GanttCustomEditors from './gantt_custom_editors';
import { selectTabPreferences } from '../../redux/slices/app/tab.slice';
import store from '../../redux/store';
import { selectCalendarEntities, selectTimeUnitById, selectTimeUnits } from '../../redux/slices/app/calendar.slice';
import {
    selectAllCustomFields,
    selectModeSelected,
    selectPlanningCustomFields,
} from '../../redux/slices/app/planning.slice';
import { selectUserPreferences } from '../../redux/slices/app/user.slice';
import { checkCircleBase64, circleBase64, lockBase64 } from '../../helpers/icons';
import i18n from '../../i18n';
import quantityUnits from './activity_config';
import { ACTIVITY_STATE } from '../../helpers/planning';
import GanttConfigurations from './gantt_config';

const { DEFAULT_COLUMNS } = GanttConfigurations;

const getBaseColumns = ({
    state,
    tabPreferences,
    timeUnits,
    calendarsDictionary,
    allCustomFields,
    modeSelected,
    columnAlignmentPreferences,
    planningColumnWidths,
}) => {

    const { t } = i18n;
    const baseGanttColumns = [
        {
            id: 'id',
            name: 'identity',
            label: t('columns.id'),
            serverName: 'identity',
            width: planningColumnWidths.id ?? 200,
            resize: true,
            align: columnAlignmentPreferences.id ?? 'left',
            template: (task) => (task.isExtra ? '' : task.identity),
            editor: { type: 'text', map_to: 'identity' },
        },
        {
            id: 'description',
            name: 'description',
            label: t('columns.description'),
            serverName: 'description',
            width: planningColumnWidths.description ?? 100,
            resize: true,
            align: columnAlignmentPreferences.description ?? 'center',
            editor: { type: 'text', map_to: 'description' },
        },
        {
            id: 'name',
            name: 'text',
            label: t('columns.name'),
            serverName: 'name',
            width: planningColumnWidths.name ?? 180,
            resize: true,
            align: columnAlignmentPreferences.name ?? 'left',
            template: (task) =>
                task.isResource
                    ? `<div class="italic" style="padding-left: 20px; font-style: italic;">${task.text}</div>`
                    : task.text,
            editor: { type: 'text', map_to: 'text' },
        },
        {
            id: 'startDate',
            name: 'start_date',
            label: t('columns.start_date'),
            serverName: 'startDate',
            width: planningColumnWidths.startDate ?? 110,
            resize: true,
            align: columnAlignmentPreferences.startDate ?? 'center',
            template: ganttColumnStartDateTemplate,
            editor:
                tabPreferences?.gantt_parameters?.hideHours || tabPreferences?.gantt_parameters?.hideHours === undefined
                    ? { type: 'custom_datepicker_editor', map_to: 'start_date' }
                    : {
                          type: 'datetime_editor',
                          map_to: 'start_date',
                      },
        },
        {
            id: 'endDate',
            name: 'end_date',
            label: t('columns.end_date'),
            serverName: 'endDate',
            width: planningColumnWidths.endDate ?? 110,
            resize: true,
            align: columnAlignmentPreferences.endDate ?? 'center',
            template: ganttColumnEndDateTemplate,
            editor:
                tabPreferences?.gantt_parameters?.hideHours || tabPreferences?.gantt_parameters?.hideHours === undefined
                    ? { type: 'custom_datepicker_editor', map_to: 'end_date' }
                    : {
                          type: 'datetime_editor',
                          map_to: 'end_date',
                      },
        },
        {
            id: 'duration',
            name: 'duration',
            label: t('columns.duration'),
            serverName: 'duration',
            width: planningColumnWidths.duration ?? 50,
            resize: true,
            align: columnAlignmentPreferences.duration ?? 'center',
            template: ganttColumnDurationTemplate,
            editor: {
                type: 'number',
                map_to: 'roundedDuration',
                min: 0,
            },
        },
        {
            id: 'progress',
            name: 'progress',
            label: t('columns.progress'),
            serverName: 'avancement',
            width: planningColumnWidths.duration ?? 50,
            resize: true,
            align: columnAlignmentPreferences.duration ?? 'center',
            template: (task) => (task.isExtra || task.virtual ? '' : (task.progress * 100).toFixed(0)),
            editor: {
                type: 'progress_editor',
                map_to: 'avancement',
            },
        },
        {
            id: 'duration_unit',
            name: 'duration_unit',
            serverName: 'dayDefinitionName',
            label: t('columns.duration_unit'),
            width: planningColumnWidths.duration_unit ?? 50,
            resize: true,
            align: columnAlignmentPreferences.duration_unit ?? 'center',
            template: (task) => selectTimeUnitById(state, Number(task.dayDefinitionId))?.name,
            editor: {
                type: 'select',
                map_to: 'dayDefinitionId',
                options: timeUnits.map((val) => ({ key: val.id, label: val.name })),
            },
        },
        {
            id: 'calendar',
            name: 'calendar',
            label: t('columns.calendar'),
            serverName: 'calendarName',
            width: planningColumnWidths.calendar ?? 100,
            resize: true,
            align: columnAlignmentPreferences.calendar ?? 'center',
            template: (task) => calendarsDictionary?.[task.calendarId]?.name,
            editor: {
                type: 'select',
                map_to: 'calendarId',
                options: Object.values(calendarsDictionary).reduce((agg, calendar) => {
                    agg.push({ label: calendar.name, key: calendar.id });

                    return agg;
                }, []),
            },
        },
        {
            id: 'calcul',
            name: 'calcul',
            serverName: 'champPMAutoId',
            label: t('columns.calcul'),
            width: planningColumnWidths.calcul ?? 50,
            resize: true,
            align: columnAlignmentPreferences.calcul ?? 'center',
            template: (task) =>
                task.isExtra ? '' : allCustomFields.find((field) => field.id === Number(task.champPMAutoId))?.name,
            editor: {
                type: 'select',
                map_to: 'champPMAutoId',
                options: allCustomFields
                    .filter((customField) =>
                        [
                            DefaultUnifiedFieldId.quantity,
                            DefaultUnifiedFieldId.quantity,
                            DefaultUnifiedFieldId.yield,
                        ].includes(customField.id)
                    )
                    .map((val) => ({ key: val.id, label: val.name })),
            },
        },
        {
            id: 'yield',
            name: 'yield',
            label: t('columns.yield'),
            serverName: 'yield',
            width: planningColumnWidths.yield ?? 50,
            resize: true,
            align: columnAlignmentPreferences.yield ?? 'center',
            template: (task) =>
                task.isExtra || task.virtual || task.isResource
                    ? ''
                    : `${Number(task.yield).toFixed(2)} ${getYieldUnit(task.quantityUnit, task.dayDefinitionId)}`,
            editor: {
                type: 'number',
                map_to: 'yield',
            },
        },
        {
            id: 'quantity',
            name: 'quantity',
            label: t('columns.quantity'),
            serverName: 'quantity',
            width: planningColumnWidths.quantity ?? 120,
            min_width: 80,
            resize: true,
            align: columnAlignmentPreferences.quantity ?? 'center',
            template: (task) =>
                task.isExtra
                    ? ''
                    : `${Number(task.quantity).toFixed(2) ?? '-'} ${getQuantityUnitName(task.quantityUnit)}`,
            editor: {
                type: 'quantity_editor',
                map_to: 'quantity',
                selectOptions: quantityUnits,
            },
        },
        {
            id: 'predecessors',
            name: 'predecessors',
            label: t('columns.predecessors'),
            serverName: 'predecessors',
            width: planningColumnWidths.predecessors ?? 100,
            resize: true,
            align: columnAlignmentPreferences.predecessors ?? 'left',
            template: (task) => {
                const text = [];
                if (task.type === 'task') {
                    task.$target.forEach((linkId) => {
                        const linkInfo = window.ganttInstance.getLink(linkId);
                        if (linkInfo?.dataAPI) {
                            const predecessorTask = window.ganttInstance.getTask(linkInfo.source);
                            const timeUnitLabel = timeUnits.find(
                                (item) => item.id === linkInfo.dataAPI.dayDefinitionId
                            )?.name;
                            if (linkInfo.dataAPI.decalage !== 0) {
                                text.push(
                                    `${predecessorTask.identity} ${linkInfo.dataAPI.type}${
                                        linkInfo.dataAPI.decalage > 0 ? '+' : ''
                                    }${linkInfo.dataAPI.decalage}(${timeUnitLabel})`
                                );
                            } else {
                                text.push(`${predecessorTask.identity}`);
                            }
                        }
                    });
                }
                return `<span title="${text.join('&#013;')}">${text.join(';')}</span>`;
            },
            editor: null,
            sort: false,
        },
        {
            id: 'successors',
            name: 'successors',
            label: t('columns.successors'),
            serverName: 'successors',
            width: planningColumnWidths.successors ?? 100,
            resize: true,
            align: columnAlignmentPreferences.successors ?? 'left',
            template: (task) => {
                const text = [];
                if (task.type === 'task') {
                    task.$source.forEach((linkId) => {
                        const linkInfo = window.ganttInstance.getLink(linkId);
                        if (linkInfo?.dataAPI) {
                            const successorTask = window.ganttInstance.getTask(linkInfo.target);
                            const timeUnitLabel = timeUnits.find(
                                (item) => item.id === linkInfo.dataAPI.dayDefinitionId
                            )?.name;
                            if (linkInfo.dataAPI.decalage !== 0) {
                                text.push(
                                    `${successorTask.identity} ${linkInfo.dataAPI.type}${
                                        linkInfo.dataAPI.decalage > 0 ? '+' : ''
                                    }${linkInfo.dataAPI.decalage}(${timeUnitLabel})`
                                );
                            } else {
                                text.push(`${successorTask.identity}`);
                            }
                        }
                    });
                }
                return `<span title="${text.join('&#013;')}">${text.join(';')}</span>`;
            },
            editor: null,
            sort: false,
        },
    ];

    const ganttColumnExtraColumns = [];

    if (modeSelected === 'live') {
        ganttColumnExtraColumns.push({
            id: 'valid',
            name: 'valid',
            label: t('columns.state'),
            serverName: 'activityState',
            width: planningColumnWidths.validate ?? 44,
            resize: true,
            align: columnAlignmentPreferences.validate ?? 'center',
            template: (task) => {
                let html = '';
                if (task.isExtra || task.virtual || task.isResource) {
                    return '';
                }
                let iconSize = tabPreferences.gantt_parameters?.rowHeight || 20;
                if (iconSize > 20) {
                    iconSize = 20;
                }
                if (!task.virtual && !task.locked) {
                    const icon = task.status === ACTIVITY_STATE.UNVALID ? circleBase64 : checkCircleBase64;
                    // if (task.status === ACTIVITY_STATE.VALID) {
                    //     // make icon warning when has conflict incoming link
                    //     const targetlinksCategories = task.$target.map(
                    //         (linkId) => window.ganttInstance.getLink(linkId).dataAPI?.category?.id
                    //     );
                    //     if (targetlinksCategories.filter((i) => i === -2).length > 0) {
                    //         icon = warningBase64;
                    //     }
                    // }

                    html = `<div style="display: flex; align-items: center; justify-content: center; height: 100%; cursor: pointer;"><div data-action="${
                        task.status === ACTIVITY_STATE.UNVALID ? 'validate' : 'invalidate'
                    }" style=" height: ${iconSize}px; width: ${iconSize}px; background-image: url(${icon})" background-size: auto 100%; background-repeat: no-repeat; cursor: pointer;"></div></div>`;
                } else {
                    html = `<div style="display: flex; align-items: center; justify-content: center; height: 100%;"> <div style=" height: ${iconSize}px; width: ${iconSize}px; background-image: url(${lockBase64})" background-size: auto 100%; background-repeat: no-repeat;"></div></div>`;
                }
                return html;
            },
        });
    }
    return { baseGanttColumns, ganttColumnExtraColumns };
};

const updateGanttColumns = (ganttColumnsPreferences) => {
    const { t } = i18n;

    const state = store.getState();
    const tabPreferences = selectTabPreferences(state);
    const userPreferences = selectUserPreferences(state);
    const timeUnits = selectTimeUnits(state);
    const calendarsDictionary = selectCalendarEntities(state);
    const allCustomFields = selectAllCustomFields(state);
    const planningCustomFields = selectPlanningCustomFields(state);
    const modeSelected = selectModeSelected(state);
    const columnWidthPreferences = tabPreferences?.gantt_column_widths ?? {};
    const planningColumnWidths = columnWidthPreferences ?? {};

    const columnAlignmentPreferences =
        tabPreferences?.gantt_columns_alignment || userPreferences?.default_gantt_columns_alignment || {};
    const { baseGanttColumns, ganttColumnExtraColumns } = getBaseColumns({
        state,
        tabPreferences,
        userPreferences,
        timeUnits,
        calendarsDictionary,
        allCustomFields,
        modeSelected,
        columnAlignmentPreferences,
        planningColumnWidths,
    });
    const ganttColumns = baseGanttColumns
        .concat(
            planningCustomFields.reduce(
                (agg, customField) =>
                    agg.concat({
                        id: `${customField.name === JOB_FIELD ? 'jobs' : `customField#${customField.id}`}`,
                        name: `customField#${customField.id}`,
                        label: `${customField.name === JOB_FIELD ? t('columns.jobs') : `${customField.name}`}`,
                        serverName: `customField#${customField.id}`,
                        width: planningColumnWidths[`customField#${customField.id}`] ?? 60,
                        resize: true,
                        align: columnAlignmentPreferences[`customField#${customField.id}`] ?? 'center',
                        template: (task) => {
                            const text =
                                customField.name === JOB_FIELD
                                    ? (() => {
                                          const jobsArray = (task.jobId || []).map((job) =>
                                              job === ALL_JOBS ? t('general.all_jobs') : job
                                          );
                                          return `<span title="${jobsArray.join('&#013')}">${jobsArray.join(
                                              ', '
                                          )}</span>`;
                                      })()
                                    : task[`customField#${customField.id}`] ?? null;

                            return text;
                        },
                        editor:
                            customField.name === JOB_FIELD
                                ? null
                                : {
                                      // eslint-disable-next-line
                                      type: customField.type.choice
                                          ? 'select'
                                          : customField.type.text
                                          ? 'custom_text_editor'
                                          : 'number',
                                      map_to: `customField#${customField.id}`,
                                      options: sortBy(
                                          Object.keys(customField.type.choices).reduce(
                                              (options, customFieldChoiceName) => {
                                                  options.push({
                                                      label:customFieldChoiceName,
                                                      key: customFieldChoiceName,
                                                  });

                                                  return options;
                                              },
                                              []
                                          ),
                                          (obj) => obj.label
                                      ),
                                  },
                    }),
                []
            )
        )
        .concat(ganttColumnExtraColumns);
    // storing all columns config (using in left, center and right text templates)
    window.ganttInstance.config.fullColumns = [...ganttColumns];
    const columnsOrder = tabPreferences?.gantt_columns_order || userPreferences?.default_gantt_columns_order || {};
    const fullColumns = ganttColumns.reduce((agg, column) => {
        if (ganttColumnsPreferences && Object.keys(ganttColumnsPreferences).length) {
            if (ganttColumnsPreferences.includes(column.id)) {
                agg.push({ ...column, order: columnsOrder[column?.id] ?? 99 });
            }
        } else if (DEFAULT_COLUMNS.includes(column.id)) {
            agg.push({ ...column, order: columnsOrder[column?.id] ?? 99 });
        }

        if (['add', 'action_buttons', 'split_task'].includes(column.id)) {
            agg.push({ ...column, order: columnsOrder[column?.id] ?? 99 });
        }

        return agg;
    }, []);

    window.ganttInstance.config.columns = fullColumns
        .sort((a, b) => a.order - b.order)
        .map((column, index) => ({ ...column, tree: index === 0, align: index === 0 ? 'left' : column.align }));
    GanttCustomEditors.ganttCustomEditors(tabPreferences);
};

export default updateGanttColumns;
