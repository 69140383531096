import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { clone, uniq } from 'lodash';
import ActivityService from '../../services/activity.service';
import CustomFieldService from '../../services/custom-field.service';
import { ACTIVITY_STATE, formatGanttTask, dateToJsDate, dateToTimestamp } from '../../helpers/planning';
// import { addResourceIcon } from '../../helpers/icons';
import DefaultUnifiedFieldId from '../../constants/DefaultPlanningField';
import { notificationInfo } from '../../helpers/notification';
import { PARENT_TASK_CONTEXT_MENU } from '../../constants/GanttContextMenu';
import { getExpandOrCollapseAction } from '../../helpers/context-menu';
import { RESOURCE_VIEW_MODE } from '../../constants/Generic';
import store from '../../redux/store';
import { updateTabPreferences } from '../../redux/slices/app/tab.slice';
import { refetchCalendars } from '../../redux/thunks/calendar-slice.thunk';

// import { RESOURCE_VIEW_MODE } from '../../constants/Generic';

const MAX_SELECTED_TASK_COUNT = 500;
// Suppression de tous les événements lié à un élément
const removeAllEventListener = (el) => {
    if (el) {
        const elClone = el.cloneNode(true);
        el.parentNode.replaceChild(elClone, el);
        return elClone;
    }
    return null;
};
/* eslint-disable */
// Affichage des boutons flottants
const showTaskActions = (taskId = null, needTimeOut = false) => {
    const activityId = taskId ?? window.ganttInstance?.getSelectedId() ?? null;
    if (activityId) {
        setTimeout(
            () => {
                const taskQuickInfo = window.ganttInstance.ext.quickInfo;
                taskQuickInfo.show(activityId);
            },
            needTimeOut ? 300 : 0
        );
    }
};
// Méthode pour afficher ou masquer les enfants d'une activité
const toggleShowAllLevel = (tasks, hidden) => {
    tasks.forEach((i) => {
        if (window.ganttInstance.isTaskExists(i)) {
            const child = window.ganttInstance.getTask(i);
            child.hidden = hidden;
            window.ganttInstance.refreshTask(child.id);
            if (child.type === window.ganttInstance.config.types.customProject) {
                toggleShowAllLevel(window.ganttInstance.getAllChildren(i), hidden);
            }
        }
    });
};

const updateTaskState = (taskId, status) => {
    const task = window.ganttInstance.getTask(taskId);
    // updateActivitiesDictionary(task.serverId, {
    //     activityState: status,
    // });
    task.status = status;
    if (status === ACTIVITY_STATE.LOCKED) {
        task.locked = true;
    } else {
        task.locked = false;
    }
    // window.ganttInstance.unselectTask(task.id);

    task.data_api.activityState = status;
    window.ganttInstance.updateTask(task.id);
};

const updateTaskType = (id, task, condition = 0) => {
    const currentTask = window.ganttInstance.getTask(id);
    const children = window.ganttInstance.getAllChildren(currentTask.id);
    let newType = children.length === condition ? 'task' : window.ganttInstance.config.types.customProject;
    // milestone
    if (newType === 'task' && currentTask.durationApi === 0) {
        newType = 'milestone';
    }
    if (task.type !== newType) {
        currentTask.type = newType;
        window.ganttInstance.updateTask(currentTask.id);
    }
};

/* Méthode changement de l'état des parents de l'activité (récursivement) */

export const updateParentState = (parentId, updateActivitiesDictionary) => {
    if (parentId !== 0) {
        const allChildStatus = window.ganttInstance
            .getChildren(parentId)
            .map((id) => window.ganttInstance.getTask(id).status);
        const combinedChildStatus = uniq(allChildStatus);
        if (combinedChildStatus.length === 1) {
            updateTaskState(parentId, combinedChildStatus[0], updateActivitiesDictionary);
        } else if (combinedChildStatus.find((i) => i === ACTIVITY_STATE.UNVALID)) {
            updateTaskState(parentId, ACTIVITY_STATE.UNVALID, updateActivitiesDictionary);
        } else {
            // combo LOCKED and VALID make parent VALID
            updateTaskState(parentId, ACTIVITY_STATE.VALID, updateActivitiesDictionary);
        }

        if (window.ganttInstance.getParent(parentId) !== 0) {
            updateParentState(window.ganttInstance.getParent(parentId), updateActivitiesDictionary);
        }
    }
};

// let previousScrollState;

const addGanttEvents = ({
    tabPreferencesObject,
    planningSelected,
    fetchCalendars,
    incrementReRenderGantt,
    incrementRefreshGantt,
    requestError,
    refreshPlanningSelected,
    updateActivitiesDictionary,
    removeActivity,
    isUserJob,
    isMultiJobUser,
    canAccess,
    notificationError,
    addNewTask,
    openTaskDrawer,
    closeTaskDrawer,
    addVirtualTasks,
    timeUnits,
    hasBaseLine,
    i18n,
    updateActivityByBroadcast,
}) => {
    const { t } = i18n;
    const tabPreferences = {...tabPreferencesObject};
    const isGroupement = tabPreferences?.filtered_group?.groupingType === 'custom_fields';
    // previousScrollState = tabPreferences.gantt_scroll_state;

    /* Ouverture du volet de modification */
    window.ganttInstance.showLightbox = async (taskId, tab = 'activity_details') => {
        const task = window.ganttInstance.getTask(taskId);

        if (task.virtual) {
            return false;
        }

        if (task.$new) {
            if (isGroupement) {
                notificationError(
                    t('gantt_events.error_create_activity'),
                    t('gantt_events.cannot_create_activity_when_grouping')
                );
                window.ganttInstance.deleteTask(taskId);
            }
            await addNewTask(taskId);
        } else {
            openTaskDrawer(taskId, tab);
        }

        return true;
    };
    /* Fermeture du volet de modification */
    window.ganttInstance.hideLightbox = () => {
        closeTaskDrawer();
    };

    /* Action => Validation, Invalidation d’une activité avec ses enfants (récursivement) */
    const updateValidState = (taskIds, status) => {
        taskIds.forEach((taskId) => {
            const timeStart = Date.now();
            const task = window.ganttInstance.getTask(taskId);
            if (isUserJob(task?.jobId) || isMultiJobUser) {
                if (task.status !== ACTIVITY_STATE.LOCKED) {
                    updateTaskState(taskId, status, updateActivitiesDictionary);
                }
                const taskChildren = window.ganttInstance.getAllChildren(taskId);
                if (taskChildren?.length > 0) {
                    updateValidState(taskChildren, status);
                }
            }
        });
    };

    window.ganttInstance.attachEvent('onTaskClick', async (activityId, e) => {
        const currentTask = window.ganttInstance.getTask(activityId);
        if (currentTask.isExtra || !canAccess) {
            return false;
        }
        const button = e.target.closest('[data-action]');
        if (button && activityId) {
            if (
                !(isUserJob(currentTask?.jobId) || isMultiJobUser) &&
                Number(currentTask.serverId) !== planningSelected.rootActivityId
            ) {
                notificationError(t('gantt_events.activity_validation'), t('gantt_events.cannot_update_activity'));
                return false;
            }
            const action = button.getAttribute('data-action');

            switch (action) {
                case 'validate':
                    if (currentTask.locked) {
                        notificationError(
                            t('gantt_events.activity_validation'),
                            t('gantt_events.cannot_update_activity')
                        );
                        break;
                    }
                    if (!currentTask.virtual) {
                        if (currentTask.serverId === planningSelected.rootActivityId) {
                            window.disableBroadcastUpdate = true;
                        }
                        await ActivityService.updateActivityState(currentTask.serverId, ACTIVITY_STATE.VALID)
                            // .then(() => {
                            //     // updateValidState([activityId], ACTIVITY_STATE.VALID);
                            //     // updateParentState(
                            //     //     window.ganttInstance.getParent(activityId),
                            //     //     updateActivitiesDictionary
                            //     // );
                            // })
                            // .catch((e) => {
                            //     console.log('error', e);
                            //     notificationError(
                            //         t('gantt_events.activity_validation'),
                            //         t('gantt_events.cannot_update_activity')
                            //     );
                            // });
                    }
                    break;
                case 'invalidate':
                    if (currentTask.locked) {
                        notificationError(
                            t('gantt_events.activity_validation'),
                            t('gantt_events.cannot_update_activity')
                        );
                        break;
                    }
                    if (!currentTask.virtual) {
                        if (currentTask.serverId === planningSelected.rootActivityId) {
                            window.disableBroadcastUpdate = true;
                        }
                        await ActivityService.updateActivityState(currentTask.serverId, ACTIVITY_STATE.UNVALID)
                            // .then(() => {
                            //     updateValidState([activityId], ACTIVITY_STATE.UNVALID);
                            //     updateParentState(
                            //         window.ganttInstance.getParent(activityId),
                            //         updateActivitiesDictionary
                            //     );
                            // })
                            // .catch((e) => {
                            //     console.log('error', e);
                            //     notificationError(
                            //         t('gantt_events.activity_validation'),
                            //         t('gantt_events.cannot_update_activity')
                            //     );
                            // });
                    }
                    break;

                default:
                    console.error(`Action "${action}" not implemented`);
            }
            return false;
        }
        return true;
    });
    /* Modification de la taille d’une colonne */
    window.ganttInstance.attachEvent('onColumnResizeEnd', (index, column, newWidth) => {
        const loadedColumnWidthPreferences = tabPreferences.gantt_column_widths ?? {};
        const loadedPlanningColumnWidths = loadedColumnWidthPreferences[planningSelected.id] ?? {};
        const updatedPlanningColumnWidths = { ...loadedPlanningColumnWidths, [column.id]: newWidth };

        store.dispatch(
            updateTabPreferences({
                gantt_column_widths: updatedPlanningColumnWidths,
            })
        );

        return true;
    });
    /* Gérer l’affichage des boutons flottants (activité sélectionnée ou au scroll) */

    window.ganttInstance.attachEvent('onDataRender', () => {
        showTaskActions();
    });
    window.ganttInstance.attachEvent('onGanttScroll', () => {
        showTaskActions(null, true);
    });
    window.ganttInstance.attachEvent('onTaskRowClick', (activityId) => {
        showTaskActions(activityId);
    });
    window.ganttInstance.attachEvent('onTaskDblClick', (id) => {
        if (window.ganttInstance.config.readonly) {
            window.ganttInstance.showLightbox(id);
        }
        return true;
    });
    /* Gestion affichage des boutons flottants (contenu, positionnement, actions au clic des boutons) */
    window.ganttInstance.attachEvent('onQuickInfo', (activityId) => {
        try {
            const currentTask = window.ganttInstance.getTask(activityId);
            const rowNode = window.ganttInstance.getTaskRowNode(activityId);
            const taskQuickInfo = window.ganttInstance.ext.quickInfo;
            const selectedTasks = window.ganttInstance.getSelectedTasks();
            if (selectedTasks.length > 1) {
                taskQuickInfo.hide();
                return;
            }
            if (
                isGroupement ||
                currentTask.isExtra ||
                !canAccess ||
                !(isUserJob(currentTask?.jobId) || isMultiJobUser) ||
                window.ganttInstance.config.readonly ||
                currentTask.isResource
            ) {
                taskQuickInfo.hide();
                return;
            }
            const quickInfoElement = document.getElementsByClassName('gantt_cal_quick_info')[0];
            if (rowNode && quickInfoElement) {
                let splitTasks = tabPreferences.gantt_split_tasks || [];
                let splitTaskButtonHtml = '';
                let splitTaskAction = '';
                // let addResourceButtonHtml = '';
                // if (tabPreferences?.gantt_parameters?.showResources !== RESOURCE_VIEW_MODE(i18n).NOTHING.value) {
                //     addResourceButtonHtml = `<div title="${t(
                //         'gantt_events.assign_resource'
                //     )}" id="add_resource_quickInfo" class="gantt_task_action mr-0.5" style="width: 25px; height: 25px; background-image: url(${addResourceIcon})"></div>`;
                // }
                if (currentTask.type === window.ganttInstance.config.types.customProject) {
                    const currentTaskSplitted = splitTasks.indexOf(currentTask.identity) !== -1;
                    if (currentTaskSplitted) {
                        splitTaskButtonHtml = `<div title="${t(
                            'gantt_events.disable_summary_view'
                        )}" id="split_task_quickInfo" class="gantt_task_action gantt_unsplit_task mr-0.5" style="width: 25px; height: 25px;"></div>`;
                        splitTaskAction = 'unsplit';
                    } else {
                        splitTaskButtonHtml = `<div title="${t(
                            'gantt_events.enable_summary_view'
                        )}" id="split_task_quickInfo" class="gantt_task_action gantt_split_task mr-0.5" style="width: 25px; height: 25px;"></div>`;
                        splitTaskAction = 'split';
                    }
                }
                let topValue = Number(rowNode.style.top.split('p')[0]) + 45;
                taskQuickInfo.setContent({
                    content: `<div class="gantt_add_clone gantt_task_action mr-0.5" id="add_next_task_quickInfo" title="${t(
                        'gantt_events.add_activity_after'
                    )}" style="width: 25px; height: 25px;"></div><div title="${t(
                        'gantt_events.add_child_activity'
                    )}" id="add_task_quickInfo" class="gantt_add_inside gantt_task_action mr-0.5" style="width: 25px; height: 25px;"></div><div title="${t(
                        'gantt_events.edit_activity'
                    )}" id="edit_task_quickInfo" class="gantt_task_action gantt_edit mr-0.5" style="width: 25px; height: 25px;"></div>${splitTaskButtonHtml}<div title="${t(
                        'gantt_events.duplicate_activity'
                    )}" id="duplicate_task_quickInfo" class="gantt_task_action gantt_duplicate mr-0.5" style="width: 25px; height: 25px;"></div><div title="${t(
                        'gantt_events.delete_activity'
                    )}" id="delete_task_quickInfo" class="gantt_task_action gantt_delete mr-0.5" style="width: 25px; height: 25px;"></div>`,
                    buttons: [],
                });
                if (+currentTask.serverId !== planningSelected.rootActivityId) {
                    topValue = Number(rowNode.style.top.split('p')[0]) - 25;
                    window.ganttInstance.$grid_data.appendChild(quickInfoElement);
                } else {
                    window.ganttInstance.$grid.appendChild(quickInfoElement);
                }
                quickInfoElement.style.top = `${topValue}px`;
                setTimeout(() => {
                    const addSubTaskButton = removeAllEventListener(document.getElementById('add_task_quickInfo'));
                    if (addSubTaskButton) {
                        addSubTaskButton.addEventListener('click', async () => {
                            if (currentTask.status !== ACTIVITY_STATE.UNVALID) {
                                notificationError(
                                    t('gantt_events.error_create_activity'),
                                    t('gantt_events.cannot_create_activity_here')
                                );
                                return false;
                            }
                            const isHourScale = window.ganttInstance.config.scaleName === 'hours';
                            if (isHourScale) {
                                const currentTaskMinute = moment(currentTask.start_date).minutes();
                                let newTaskStartDate = moment(currentTask.start_date);
                                if (currentTaskMinute > 0) {
                                    newTaskStartDate = newTaskStartDate.startOf('hour').add(1, 'hour');
                                }
                                const newTaskEndDate = moment(newTaskStartDate).add(1, 'day');
                                const newTaskId = window.ganttInstance.addTask(
                                    {
                                        text: t('gantt_events.new_activity'),
                                        start_date: newTaskStartDate.toDate(),
                                        end_date: newTaskEndDate.toDate(),
                                    },
                                    activityId
                                );
                                window.ganttInstance.open(activityId);
                                await addNewTask(newTaskId, true);
                            } else {
                                const newTaskId = window.ganttInstance.addTask(
                                    {
                                        text: t('gantt_events.new_activity'),
                                        unscheduled: true,
                                    },
                                    activityId
                                );
                                window.ganttInstance.open(activityId);
                                await addNewTask(newTaskId);
                            }
                            return 'ok';
                        });
                    }
                    const addNextTaskButton = removeAllEventListener(
                        document.getElementById('add_next_task_quickInfo')
                    );
                    if (addNextTaskButton) {
                        addNextTaskButton.addEventListener('click', async () => {
                            if (+currentTask.serverId === planningSelected.rootActivityId) {
                                notificationError(
                                    t('gantt_events.error_create_activity'),
                                    t('gantt_events.cannot_create_activity_after_project')
                                );
                                return false;
                            }
                            let newNextTask;
                            const isHourScale = window.ganttInstance.config.scaleName === 'hours';
                            if (isHourScale) {
                                const parentTask = window.ganttInstance.getTask(
                                    window.ganttInstance.getParent(activityId)
                                );
                                const currentTaskMinute = moment(parentTask.start_date).minutes();
                                let newTaskStartDate = moment(parentTask.start_date);
                                if (currentTaskMinute > 0) {
                                    newTaskStartDate = newTaskStartDate.startOf('hour').add(1, 'hour');
                                }
                                const newTaskEndDate = moment(newTaskStartDate).add(1, 'day');
                                const newTaskId = window.ganttInstance.addTask(
                                    {
                                        text: t('gantt_events.new_activity'),
                                        start_date: newTaskStartDate.toDate(),
                                        end_date: newTaskEndDate.toDate(),
                                    },
                                    window.ganttInstance.getParent(activityId)
                                );
                                const newTaskIndex = window.ganttInstance.getNextSibling(activityId)
                                    ? window.ganttInstance.getTaskIndex(window.ganttInstance.getNextSibling(activityId))
                                    : null;
                                newNextTask = await addNewTask(newTaskId, true, newTaskIndex);
                            } else {
                                const newTaskId = window.ganttInstance.addTask(
                                    {
                                        text: t('gantt_events.new_activity'),
                                        unscheduled: true,
                                    },
                                    window.ganttInstance.getParent(activityId)
                                );
                                const newTaskIndex = window.ganttInstance.getNextSibling(activityId)
                                    ? window.ganttInstance.getTaskIndex(window.ganttInstance.getNextSibling(activityId))
                                    : null;
                                newNextTask = await addNewTask(newTaskId, false, newTaskIndex);
                            }
                            if (newNextTask) {
                                await ActivityService.moveAfter(currentTask.serverId, [newNextTask.id]);
                            }
                            return 'ok';
                        });
                    }
                    const editTaskButton = removeAllEventListener(document.getElementById('edit_task_quickInfo'));
                    if (editTaskButton) {
                        editTaskButton.addEventListener('click', () => {
                            window.ganttInstance.showLightbox(activityId);
                        });
                    }

                    const duplicateTaskButton = removeAllEventListener(
                        document.getElementById('duplicate_task_quickInfo')
                    );
                    if (duplicateTaskButton) {
                        duplicateTaskButton.addEventListener('click', async () => {
                            if (+currentTask.serverId === planningSelected.rootActivityId) {
                                notificationError(
                                    t('gantt_events.duplicate_activity'),
                                    t('gantt_events.cannot_duplicate_project')
                                );
                                return 'false';
                            }
                            await ActivityService.duplicateActivity(currentTask.serverId).then((res) => {
                                if (currentTask.type !== window.ganttInstance.config.types.customProject) {
                                    store.dispatch(updateActivitiesDictionary(res.id, res));
                                    store.dispatch(incrementRefreshGantt());
                                } else {
                                    store.dispatch(refreshPlanningSelected(true));
                                }
                            });
                            return 'ok';
                        });
                    }
                    const deleteTaskButton = removeAllEventListener(document.getElementById('delete_task_quickInfo'));
                    if (deleteTaskButton) {
                        deleteTaskButton.addEventListener('click', async () => {
                            if (+currentTask.id === planningSelected.rootActivityId) {
                                notificationError(
                                    t('gantt_events.delete_activity'),
                                    t('gantt_events.cannot_delete_project')
                                );
                                return 'false';
                            }
                            if (currentTask.status !== ACTIVITY_STATE.VALID && !currentTask.locked) {
                                // eslint-disable-next-line no-case-declarations
                                const question =
                                    currentTask.type === 'folder'
                                        ? t('actvity_tab.delete_parent_task_text')
                                        : t('activity_tab.delete_normal_task_text');
                                // eslint-disable-next-line no-alert
                                if (!window.confirm(question)) {
                                    return false;
                                }

                                await ActivityService.deleteActivity(currentTask.serverId)
                                    .then(() => {
                                        window.ganttInstance.deleteTask(currentTask.id);
                                        store.dispatch(removeActivity(currentTask.serverId));
                                    })
                                    .catch(() => {
                                        notificationError(
                                            t('gantt_events.delete_activity'),
                                            t('general.generic_error')
                                        );
                                    });
                                // refreshPlanningSelected();
                            } else {
                                notificationError(
                                    t('gantt_events.delete_activity'),
                                    t('gantt_events.cannot_delete_validated_activity')
                                );
                            }
                            return 'ok';
                        });
                    }
                    const splitTaskButton = removeAllEventListener(document.getElementById('split_task_quickInfo'));
                    if (splitTaskButton) {
                        splitTaskButton.addEventListener('click', () => {
                            switch (splitTaskAction) {
                                case 'split': {
                                    window.ganttInstance.updateTask(activityId, {
                                        ...window.ganttInstance.getTask(activityId),
                                        render: 'split',
                                    });
                                    if (!currentTask.virtual) {
                                        splitTasks.push(currentTask.identity);
                                        store.dispatch(
                                            updateTabPreferences({
                                                gantt_split_tasks: splitTasks,
                                            })
                                        );
                                    }
                                    // check child tasks visibility
                                    if (!currentTask.$open) {
                                        window.ganttInstance.close(activityId);
                                    }
                                    window.ganttInstance.refreshData();
                                    window.ganttInstance.render();
                                    break;
                                }
                                case 'unsplit': {
                                    window.ganttInstance.updateTask(activityId, {
                                        ...window.ganttInstance.getTask(activityId),
                                        render: '',
                                    });
                                    if (!currentTask.virtual) {
                                        splitTasks = splitTasks.filter((i) => i !== currentTask.identity);
                                        store.dispatch(
                                            updateTabPreferences({
                                                gantt_split_tasks: splitTasks,
                                            })
                                        );
                                    }
                                    window.ganttInstance.render();
                                    break;
                                }
                                default:
                                    break;
                            }
                        });
                    }
                    // const addNewResourceButton = removeAllEventListener(
                    //     document.getElementById('add_resource_quickInfo')
                    // );
                    // if (addNewResourceButton) {
                    //     addNewResourceButton.addEventListener('click', async () => {
                    //         console.log('ato');
                    //         setSelectedTask(currentTask);
                    //         // wait for state to be updated
                    //         setTimeout(() => {
                    //             if (addNewResourceRef.current) {
                    //                 addNewResourceRef.current.openModal();
                    //             }
                    //         }, 100);
                    //         return 'ok';
                    //     });
                    // }
                }, 300);
            } else {
                taskQuickInfo.hide();
            }
        } catch (error) {
            // console.log('Cannot display quickinfo');
        }
    });
    // SPLIT TASK MANAGEMENT
    /* Gestion affichage des enfants et masquage des liens des enfants d’une activité récap. (en mode split) lors d’un repli, sauvegarde de la préférence de repli pour l’activité */
    window.ganttInstance.attachEvent('onTaskClosed', (taskId) => {
        const checkVisibility = (tasks) => {
            // eslint-disable-next-line
            for (const id of tasks) {
                const task = window.ganttInstance.getTask(id);
                const links = [...task.$source, ...task.$target];
                links.forEach((linkId) => {
                    const link = window.ganttInstance.getLink(linkId);
                    link.hidden = true;
                    window.ganttInstance.updateLink(linkId);
                });
                if (task.type === window.ganttInstance.config.types.customProject) {
                    const firstLevelChildren = window.ganttInstance.getAllChildren(id);
                    if (!window.ganttInstance.isSplitTask(task)) {
                        toggleShowAllLevel(firstLevelChildren, true);
                    } else {
                        checkVisibility(firstLevelChildren);
                    }
                }
            }
        };
        if (window.ganttInstance.isSplitTask(window.ganttInstance.getTask(taskId))) {
            checkVisibility([taskId]);
        }
        // saving preference for collapse task
        if (!window.ganttInstance.getTask(taskId).virtual) {
            const collapsedTasks = tabPreferences.gantt_collapsed_tasks || [];
            const checkExist = collapsedTasks.find((i) => i === window.ganttInstance.getTask(taskId).identity);
            if (checkExist === undefined) {
                collapsedTasks.push(window.ganttInstance.getTask(taskId).identity);
                store.dispatch(
                    updateTabPreferences({
                        gantt_collapsed_tasks: collapsedTasks,
                    })
                );
            }
        }
        window.ganttInstance.render();
    });
    /* Gestion affichage des enfants et affichage des liens des enfants d’une activité récap. (en mode split), sauvegarde de la préférence de repli pour l’activité */
    window.ganttInstance.attachEvent('onTaskOpened', (taskId) => {
        const checkVisibility = (tasks) => {
            // eslint-disable-next-line
            for (const id of tasks) {
                const task = window.ganttInstance.getTask(id);
                const links = [...task.$source, ...task.$target];
                links.forEach((linkId) => {
                    const link = window.ganttInstance.getLink(linkId);
                    if (
                        window.ganttInstance.isTaskExists(link.source) &&
                        window.ganttInstance.isTaskExists(link.target)
                    ) {
                        const taskSource = window.ganttInstance.getTask(link.source);
                        const taskTarget = window.ganttInstance.getTask(link.target);
                        if (
                            window.ganttInstance.getTask(taskSource.parent).$open &&
                            window.ganttInstance.getTask(taskTarget.parent).$open
                        ) {
                            link.hidden = false;
                            window.ganttInstance.updateLink(linkId);
                        }
                    }
                });
                if (task.type === window.ganttInstance.config.types.customProject) {
                    const firstLevelChildren = window.ganttInstance.getAllChildren(id);
                    if (!window.ganttInstance.isSplitTask(task)) {
                        toggleShowAllLevel(firstLevelChildren, false);
                    } else {
                        checkVisibility(firstLevelChildren);
                    }
                }
            }
        };
        if (window.ganttInstance.isSplitTask(window.ganttInstance.getTask(taskId))) {
            checkVisibility([taskId]);
        }
        // saving preference for collapse task
        if (!window.ganttInstance.getTask(taskId).virtual) {
            let collapsedTasks = tabPreferences.gantt_collapsed_tasks || [];
            collapsedTasks = collapsedTasks.filter((i) => i !== window.ganttInstance.getTask(taskId).identity);
            store.dispatch(
                updateTabPreferences({
                    gantt_collapsed_tasks: collapsedTasks,
                })
            );
        }
        window.ganttInstance.render();
    });
    /* Gestion de l’affichage d’un lien, Attribution de la couleur du lien lors de l’affichage du chemin critique */
    window.ganttInstance.attachEvent('onBeforeLinkDisplay', (id, link) => {
        if (window.ganttInstance.isTaskExists(link.source) && window.ganttInstance.isTaskExists(link.target)) {
            const taskPredecessor = window.ganttInstance.getTask(link.source);
            const taskSuccessor = window.ganttInstance.getTask(link.target);
            if (
                taskPredecessor.type === window.ganttInstance.config.types.customProject ||
                taskSuccessor.type === window.ganttInstance.config.types.customProject
            ) {
                const linkObj = window.ganttInstance.getLink(link.id);
                linkObj.is_virtual = true;
                window.ganttInstance.updateLink(linkObj.id);
                window.ganttInstance.deleteLink(linkObj.id);
                return false;
            }
            if (
                tabPreferences?.gantt_parameters?.showCriticalPath &&
                taskPredecessor.totalMargin === taskPredecessor.endDate &&
                taskSuccessor.totalMargin === taskSuccessor.endDate
            )
                // eslint-disable-next-line no-param-reassign
                link.color = tabPreferences?.gantt_parameters?.criticalPathColor ?? 'red';
        }
        return !link.hidden;
    });
    /* Gestion de quand est-ce qu’on peut créer un lien */
    window.ganttInstance.attachEvent('onBeforeLinkAdd', (id, link) => {
        // cannot add link when links hidden
        const parameters = tabPreferences?.gantt_parameters;
        if (parameters && parameters.showLinks === false) {
            notificationError(t('gantt_events.link_creation'), t('gantt_events.hidden_links'));
            return false;
        }
        const srcTask = window.ganttInstance.getTask(link.source);
        const dstTask = window.ganttInstance.getTask(link.target);
        // const filteredGroup = tabPreferences?.filtered_group?.[planningSelected.rootActivityId];
        // if (filteredGroup?.groupingType === 'custom_fields' && !filteredGroup?.simpleActivityOnly) {
        //     notificationError('Création de lien', 'On ne peut pas créer de lien lors d\'un groupement ayant des activités complexes');
        //     return false
        // }
        const canAddLink =
            srcTask.type !== window.ganttInstance.config.types.customProject &&
            !dstTask.type !== window.ganttInstance.config.types.customProject;
        if (!canAddLink) {
            notificationError(t('gantt_events.link_creation'), t('gantt_events.cannot_create_link_on_parent'));
        }
        return canAddLink;
    });
    /* Sauvegarde des préférences de tri */
    window.ganttInstance.attachEvent('onAfterSort', async (field, isDesc) => {
        addVirtualTasks(tabPreferences);
        await store.dispatch(
            updateTabPreferences({
                planning_sort: { field, isDesc },
            })
        );
    });
    /* Détermine si une activité peut être sélectionnée */
    window.ganttInstance.attachEvent('onBeforeTaskSelected', (id) => {
        // if (isGroupement) {
        //     return false;
        // }
        if (!canAccess) {
            return false;
        }
        const task = window.ganttInstance.getTask(id);
        if (
            (task && task.isExtra) ||
            task.isResource ||
            task.locked ||
            task.virtual ||
            task.status === ACTIVITY_STATE.VALID ||
            !(isUserJob(task?.jobId) || isMultiJobUser)
        ) {
            return false;
        }

        if (window.ganttInstance.getSelectedTasks().length >= MAX_SELECTED_TASK_COUNT) {
            notificationInfo(
                t('gantt_events.task_selection'),
                t('gantt_events.limit_error', { limit: MAX_SELECTED_TASK_COUNT })
            );
            return false;
        }
        return true;
    });
    // DRAG N DROP MANAGEMENT
    // Détermine si on peut déplacer une activité
    window.ganttInstance.attachEvent('onBeforeTaskDrag', (id, mode) => {
        if (mode === 'move' || mode === 'resize' || mode === 'progress') {
            const task = window.ganttInstance.getTask(id);
            // if duration is calculated prevent resizing
            if (mode === 'resize' && task.champPMAutoId === DefaultUnifiedFieldId.duration) {
                return false;
            }
            if (task.type === window.ganttInstance.config.types.customProject && mode !== 'progress') {
                return false;
            }
            if (!canAccess) {
                return false;
            }
            // Bloquer le deplacement ou le redimensionnement des activités valide ou verrouillée.
            if (task.locked) {
                return false;
            }
            if (task.status === ACTIVITY_STATE.VALID) {
                return false;
            }
            // prevent dragging project task when grouped
            if (isGroupement && task.realChildren?.length > 0 && mode !== 'progress') {
                return false;
            }
            if (task.virtual) {
                return false;
            }
            return isUserJob(task?.jobId) || isMultiJobUser;
        }
        return true;
    });
    // Recalculer la date de fin après déplacement (mode "move")
    // window.ganttInstance.attachEvent('onBeforeTaskChanged', (id, mode) => {
    //     if (mode === 'move') {
    //         const task = window.ganttInstance.getTask(id);
    //         const newEndDate = dateToJsDate(dateToTimestamp(task.start_date) + task.durationApi, null, false);
    //         task.end_date = newEndDate;
    //         window.ganttInstance.updateTask(id);
    //     }
    //     return true;
    // });

    /* Gestion des déplacements (horizontal) */
    window.ganttInstance.attachEvent('onAfterTaskDrag', async (id, mode) => {
        try {
            // console.log('🚀 ~ file: gantt_events.js:688 ~ window.ganttInstance.attachEvent ~ mode', mode);
            const data = window.ganttInstance.getTask(id);
            // console.log('🚀 ~ file: gantt_events.js:689 ~ window.ganttInstance.attachEvent ~ data', data);
            // task progress (avancement)

            if (mode === 'progress') {
                const updatedActivity = await ActivityService.updateActivityAvancement(
                    data.serverId,
                    Number(data.progress * 100).toFixed(0)
                );
                // do not update parent in Gantt if activities grouped
                if (isGroupement) {
                    updatedActivity.activityParentId = data.parent;
                }
                const isProject = +data.serverId === planningSelected.rootActivityId;
                if (isProject) {
                    updatedActivity.activityParentId = 0;
                }
                // const durationUnit = timeUnits.find((timeUnit) => timeUnit.id === Number(data.dayDefinitionId));

                // window.ganttInstance.updateTaskByServerId(
                //     updatedActivity.id,
                //     formatGanttTask(updatedActivity, {
                //         durationApi: Number(updatedActivity.duration / durationUnit.duration),
                //     })
                // );
            }
            const subDayScale =
                window.ganttInstance.config?.scaleName === 'hours' ||
                window.ganttInstance.config?.scaleName === 'minutes';
            if (data.type === 'task' && data.status === ACTIVITY_STATE.UNVALID) {
                if (mode === 'resize') {
                    // deplacement de la date de debut sur la premiere heure travaillé
                    if (moment(data.start_date).utc(true).valueOf() !== data.data_api.startDate) {
                        const modifyStartFunction = subDayScale
                            ? ActivityService.updateActivityStartDate
                            : ActivityService.updateActivityFirstWorkedTime;

                        const updatedActivity = await modifyStartFunction(
                            data.serverId,
                            dateToTimestamp(data.start_date)
                        );
                        // updateActivitiesDictionary(updatedActivity.id, updatedActivity);
                        // can't update task with new object (just update data that should be updated)
                        window.ganttInstance.getTask(id).startDate = updatedActivity.startDate;
                        window.ganttInstance.getTask(id).start_date = dateToJsDate(
                            updatedActivity.startDate,
                            null,
                            false
                        );
                        const durationUnit = timeUnits.find((timeUnit) => timeUnit.id === Number(data.dayDefinitionId));

                        const taskDuration = Number(updatedActivity.duration / durationUnit.duration);
                        window.ganttInstance.getTask(id).data_api = { ...updatedActivity, taskDuration };
                        window.ganttInstance.getTask(id).durationApi = taskDuration;
                        window.ganttInstance.updateTask(id);
                        // window.ganttInstance.updateTask(data.id, formatGanttTask(updatedActivity));
                    }
                    // Deplacement de la date de fin sur la derniere heure travaillé
                    else if (moment(data.end_date).utc(true).valueOf() !== data.data_api.endDate) {
                        const modifyEndFunction = subDayScale
                            ? ActivityService.updateActivityEndDate
                            : ActivityService.updateActivityLastWorkedTime;
                        const updatedActivity = await modifyEndFunction(data.serverId, dateToTimestamp(data.end_date));
                        // updateActivitiesDictionary(updatedActivity.id, updatedActivity);
                        // window.ganttInstance.updateTask(data.id, formatGanttTask(updatedActivity));
                        const durationUnit = timeUnits.find((timeUnit) => timeUnit.id === Number(data.dayDefinitionId));

                        const taskDuration = Number(updatedActivity.duration / durationUnit.duration);
                        window.ganttInstance.getTask(id).endDate = updatedActivity.endDate;
                        window.ganttInstance.getTask(id).end_date = dateToJsDate(updatedActivity.endDate, null, false);
                        window.ganttInstance.getTask(id).data_api = { ...updatedActivity, taskDuration };
                        window.ganttInstance.getTask(id).durationApi = taskDuration;
                        window.ganttInstance.updateTask(id);
                    }
                } else if (mode === 'move') {
                    // Deplacement sans changement de duree, heure par heure
                    if (moment(data.start_date).utc(true).valueOf() !== data.data_api.startDate) {
                        data.start_date = moment(data.start_date)
                            .set({ minute: 0, second: 0, millisecond: 0 })
                            .toDate();
                        const updatedActivity = await ActivityService.updateActivityMoveStart(
                            data.serverId,
                            dateToTimestamp(data.start_date)
                        );
                        // updateActivitiesDictionary(updatedActivity.id, updatedActivity);

                        window.ganttInstance.getTask(id).startDate = updatedActivity.startDate;
                        window.ganttInstance.getTask(id).start_date = dateToJsDate(
                            updatedActivity.startDate,
                            null,
                            false
                        );
                        window.ganttInstance.getTask(id).endDate = updatedActivity.endDate;
                        window.ganttInstance.getTask(id).end_date = dateToJsDate(updatedActivity.endDate, null, false);
                        window.ganttInstance.getTask(id).data_api = updatedActivity;
                        window.ganttInstance.updateTask(id);
                    }
                }
            }
            if (data.type === 'milestone' && data.status === ACTIVITY_STATE.UNVALID && mode === 'move') {
                const updatedActivity = await ActivityService.updateActivityMoveStart(
                    data.serverId,
                    dateToTimestamp(data.start_date)
                );
                // updateActivitiesDictionary(updatedActivity.id, updatedActivity);

                window.ganttInstance.getTask(id).startDate = updatedActivity.startDate;
                window.ganttInstance.getTask(id).start_date = dateToJsDate(updatedActivity.startDate, null, false);
                window.ganttInstance.getTask(id).endDate = updatedActivity.endDate;
                window.ganttInstance.getTask(id).end_date = dateToJsDate(updatedActivity.endDate, null, false);
                window.ganttInstance.getTask(id).data_api = updatedActivity;
                window.ganttInstance.updateTask(id);
            }

            window.ganttInstance.render();
        } catch (er) {
            console.error(er);
        }
    });
    // Evenement de deplacement de l'activité avec un mode de deplacement "marker"
    // a la difference du mode par defaut le gantt n'est pas redissione en direct.
    // Ameliore la vitesse et la fluidité de l'evenement.
    // Ordre de declenchement :
    // onRowDragStart - onBeforeRowDragMove - onBeforeTaskMove - taskMove - onAfterTaskMove - onBeforeRowDragEnd - onRowDragEnd
    /* Détermine si on peut déplacer une activité (vertical)  */
    window.ganttInstance.attachEvent('onRowDragStart', (id) => {
        const task = window.ganttInstance.getTask(id);
        const isValidJob = isUserJob(task?.jobId) || isMultiJobUser;
        // const isUnValidActivity = task?.data_api?.activityState === ACTIVITY_STATE.UNVALID;
        return isValidJob && canAccess;
    });
    window.ganttInstance.attachEvent('onRowDragEnd', () => {
        document.body.style.cursor = null;
    });
    // window.ganttInstance.attachEvent('onBeforeRowDragEnd', (id, parent) => {
    //     const currentTask = window.ganttInstance.getTask(id);
    //     if (currentTask.data.activityState !== ACTIVITY_STATE.UNVALID && currentTask.parent !== parent) {
    //         document.body.style.cursor = 'not-allowed';
    //         return false;
    //     }
    // });
    /* Autorise la tache a recevoir un drag ou non */
    window.ganttInstance.attachEvent('onBeforeRowDragMove', (id, parent, tIndex) => {
        const currentTask = window.ganttInstance.getTask(id);
        if (currentTask.data_api?.activityState !== ACTIVITY_STATE.UNVALID && currentTask.parent !== parent) {
            document.body.style.cursor = 'not-allowed';
            return false;
        }
        if ((window.ganttInstance._sort && tIndex !== 0) || isGroupement) {
            document.body.style.cursor = 'not-allowed';
            return false;
        }
        // prevent dragging to top
        const isNotBeforeProject = parent !== 0;
        const task = parent !== 0 ? window.ganttInstance.getTask(parent) : null;
        // prevent dragging to top of activity that has resource
        if (task?.resources?.length > 0 && tIndex === 0) {
            return false;
        }
        // refuse adding in validate activity
        const isUnValidActivity = task?.data_api?.activityState === ACTIVITY_STATE.UNVALID;
        const canDrop = isNotBeforeProject && isUnValidActivity && !task?.isExtra && !task?.locked && !task.isResource;
        if (!canDrop) {
            document.body.style.cursor = 'not-allowed';
        } else {
            document.body.style.cursor = null;
        }
        return canDrop;
    });
    /* Gestion des déplacements (vertical) */
    window.ganttInstance.attachEvent('onBeforeTaskMove', async (id, parent, tindex) => {
        document.body.style.cursor = null;
        try {
            const currentTask = window.ganttInstance.getTask(id);
            const oldParentTask = JSON.parse(JSON.stringify(window.ganttInstance.getTask(currentTask.parent)));
            const parentTask = window.ganttInstance.getTask(parent);
            let idsToMove = [currentTask.serverId];
            const selectedTasks = window.ganttInstance.getSelectedTasks();
            if (selectedTasks.length > 1) {
                idsToMove = selectedTasks.map((task) => window.ganttInstance.getTask(task).serverId);
            }
            if (tindex === 0 || (tindex === 1 && window.ganttInstance.getAllChildren(parentTask.id, true).length > 0)) {
                // console.log("moveInto", currentTask.text, parentTask.text);
                // Si la postion est 0 alors c'est un moveInto
                await ActivityService.updateActivityParent(parentTask.serverId, [...idsToMove]);
                // deleting links from gantt
                const source = parentTask.$source;
                // links that come from the task,

                source.forEach((linkId) => {
                    const link = window.ganttInstance.getLink(linkId);
                    link.is_virtual = true;
                    window.ganttInstance.updateLink(linkId);
                    window.ganttInstance.deleteLink(linkId);
                });

                const target = parentTask.$target;
                // links that come into the task,

                target.forEach((linkId) => {
                    const link = window.ganttInstance.getLink(linkId);
                    link.is_virtual = true;
                    window.ganttInstance.updateLink(linkId);
                    window.ganttInstance.deleteLink(linkId);
                });

                // console.log(parentTask.children);
                // Le moveInto place a la fin des enfants, on ramene l'activité au debut.
                const childTasks = window.ganttInstance.getChildren(parent);
                if (!window.ganttInstance._sort && childTasks && childTasks.length > 1) {
                    const firstChild = window.ganttInstance.getTask(childTasks[1]);
                    // console.log("moveAfter", firstChild.text, currentTask.text);
                    await ActivityService.moveAfter(firstChild.serverId, [...idsToMove]);
                    // console.log("moveAfter", currentTask.text, firstChild.text);
                    await ActivityService.moveAfter(idsToMove[idsToMove.length - 1], [firstChild.serverId]);
                }
            } else {
                // Si la position n'est pas 0 alors c'est un moveAfter
                // Attention, lors d'un deplacement la tache en cours de deplacement n'est pas compté dans Tindex.
                // [ex : 1 2 3, deplacement de 2 après 3, Tindex =  1 => 1(index 0) 3 (index 1)
                // noté la disparition de 2 car il est en cours de deplacement.]
                //  -> en cas de remonté de l'activité il n'y a pas de probleme.
                //  -> en cas de descente de l'activité il faut corriger l'index (index+1)
                //  -> en cas d'arborescence differnte pas de probleme.

                // Recuperation de la cible avant correction
                const wbsTargetBranches = window.ganttInstance.getWBSCode(parentTask);
                // const wbsTarget = wbsTargetBranches.concat('.').concat(tindex);
                // const targetTask = window.ganttInstance.getTaskByWBSCode(wbsTargetBranches);
                //  console.log("branche :",wbsTargetBranches, "target :",tindex, "value :", targetTask);

                // Recuperation de l'activite courante
                const wbsCurrentTask = window.ganttInstance.getWBSCode(currentTask);
                const wbsCurrentTaskBranches = wbsCurrentTask.substring(0, wbsCurrentTask.lastIndexOf('.'));
                const wbsCurrentTaskTIndex = wbsCurrentTask.substring(wbsCurrentTask.lastIndexOf('.') + 1);
                //   console.log("branche : ",wbsCurrentTaskBranches,"current : ", wbsCurrentTaskTIndex, "value :", currentTask);

                // Recuperaction de la cible apres correction
                const correction =
                    wbsTargetBranches === wbsCurrentTaskBranches && wbsCurrentTaskTIndex <= tindex ? 1 : 0;
                const wbsCorrectedTarget = wbsTargetBranches.concat('.').concat(tindex + correction);
                const targetCorrectedTask = window.ganttInstance.getTaskByWBSCode(wbsCorrectedTarget);

                // Deplacement envoyé au serveur back.
                //  console.log("target", targetCorrectedTask.text, "->", " current ", currentTask.text);
                await ActivityService.moveAfter(targetCorrectedTask.serverId, [...idsToMove]);
            }

            // updating old parent task type

            // recharger le gantt après un déplacement multiple
            if (idsToMove.length > 1) {
                store.dispatch(refreshPlanningSelected());
            } else if (tabPreferences?.gantt_parameters?.showResources === RESOURCE_VIEW_MODE(i18n).NEEDS.value) {
                updateActivityByBroadcast(oldParentTask.serverId);
                updateActivityByBroadcast(parentTask.serverId);
            }

            return true;
        } catch (error) {
            notificationError(t('gantt_events.activity_move'), t('general.generic_error'));
            store.dispatch(refreshPlanningSelected());
            return false;
        }
    });
    // INLINE EDITORS
    /* Détermine si on peut éditer la valeur d’une colonne */
    window.ganttInstance.ext.inlineEditors.attachEvent('onBeforeEditStart', (state) => {
        if (!window.ganttInstance.isSelectedTask(state.id)) {
            return false;
        }
        const ganttTask = window.ganttInstance.getTask(state.id);
        if (ganttTask.virtual) return false;
        const isProject = +state.id === planningSelected.rootActivityId;
        const isFolder =
            ganttTask.type === window.ganttInstance.config.types.customProject || ganttTask.realChildren.length > 0;
        const customFieldId = Number(state.columnName.split('#')[1]) ?? null;

        let cannotEdit = !isProject
            ? !(isUserJob(ganttTask?.jobId) || isMultiJobUser) || ganttTask.status !== ACTIVITY_STATE.UNVALID
            : ganttTask.status !== ACTIVITY_STATE.UNVALID;

        switch (state.columnName) {
            case 'id':
                cannotEdit = cannotEdit || isProject;
                break;
            case 'job':
                cannotEdit = cannotEdit || isProject;
                break;
            case 'start_date':
                cannotEdit = cannotEdit || isFolder;
                break;
            case 'end_date':
                cannotEdit = cannotEdit || isFolder;
                break;
            case 'duration':
                cannotEdit = cannotEdit || isFolder || ganttTask.champPMAutoId === DefaultUnifiedFieldId.duration;
                break;
            case 'calcul':
                cannotEdit = cannotEdit || isProject;
                break;
            case 'quantity':
                cannotEdit = cannotEdit || isProject || ganttTask.champPMAutoId === DefaultUnifiedFieldId.quantity;
                break;
            case 'yield':
                cannotEdit = cannotEdit || isProject || ganttTask.champPMAutoId === DefaultUnifiedFieldId.yield;
                break;
            case 'predecessors':
                cannotEdit = true;
                break;
            case 'successors':
                cannotEdit = true;
                break;
            default:
                if (customFieldId) {
                    cannotEdit = cannotEdit || isProject;
                }
                break;
        }
        return !cannotEdit && canAccess;
    });

    window.ganttInstance.ext.inlineEditors.attachEvent('onBeforeSave', async (state) => {
        // console.log(
        //     '🚀 ~ file: gantt_events.js:1069 ~ window.ganttInstance.ext.inlineEditors.attachEvent ~ state:',
        //     state
        // );
        const ganttTask = clone(window.ganttInstance.getTask(state.id));
        ganttTask.id = ganttTask.serverId;

        const value = clone(state.newValue);
        if (!value && ['id', 'text', 'start_date', 'end_date'].includes(state.columnName)) {
            // eslint-disable-next-line
            state.newValue = state.oldValue;
            return false;
        }
        const isProject = ganttTask.id === planningSelected.rootActivityId;
        const customFieldId = Number(state.columnName.split('#')[1]) ?? null;
        try {
            let activityUpdated = { ...ganttTask };
            switch (state.columnName) {
                case 'identity':
                    activityUpdated = await ActivityService.updateActivityIdentity(ganttTask.id, value);
                    break;
                case 'text':
                    activityUpdated = await ActivityService.updateActivityName(ganttTask.id, value);
                    break;
                case 'description':
                    activityUpdated = await ActivityService.updateActivityDescription(ganttTask.id, value);
                    break;
                case 'job':
                    activityUpdated = await ActivityService.updateActivityJobId(ganttTask.id, value);
                    break;
                // quantity column updates quantity and quantityUnit
                case 'quantity': {
                    const quantityValue = state.newValue.split('-')[0];
                    const quantityUnitValue = state.newValue.split('-')[1];
                    activityUpdated = await ActivityService.updateActivityQuantity(ganttTask.id, Number(quantityValue));
                    activityUpdated = await ActivityService.updateActivityQuantityUnit(ganttTask.id, quantityUnitValue);
                    break;
                }
                case 'yield':
                    activityUpdated = await ActivityService.updateActivityYield(ganttTask.id, value);
                    break;
                case 'calcul':
                    activityUpdated = await ActivityService.updateActivityChampPm(ganttTask.id, value);
                    break;
                case 'calendar':
                    activityUpdated = await ActivityService.updateActivityCalendarId(ganttTask.id, Number(value));
                    break;
                case 'start_date':
                    activityUpdated = await ActivityService.updateActivityStartDate(
                        ganttTask.id,
                        moment(value).utc(true).valueOf()
                        // value.clone().utc(true).valueOf()
                    );
                    break;
                case 'end_date':
                    // eslint-disable-next-line
                    state.newValue = moment(value).endOf('day').toDate();
                    activityUpdated = await ActivityService.updateActivityEndDate(
                        ganttTask.id,
                        tabPreferences?.gantt_parameters?.hideHours ||
                            tabPreferences?.gantt_parameters?.hideHours === undefined
                            ? moment(value).utc(true).endOf('day').valueOf()
                            : moment(value).utc(true).valueOf()
                    );
                    break;
                case 'duration_unit':
                    activityUpdated = await ActivityService.updateActivityTimeUnit(ganttTask.id, Number(value));
                    break;
                case 'duration': {
                    activityUpdated = await ActivityService.updateActivityDuration(ganttTask.id, Number(value));
                    break;
                }
                case 'progress':
                    activityUpdated = await ActivityService.updateActivityAvancement(ganttTask.id, Number(value));
                    break;
                default: {
                    if (!customFieldId) {
                        console.warn(`${state.columnName} Not implemented `);
                    }
                    break;
                }
            }
            // UPDATES PERFORMED BY NOTIFIER
            if (customFieldId) {
                await CustomFieldService.updateCustomFieldActivityValue(customFieldId, ganttTask.id, [value]);

                // // update style and dictionnary
                // activityUpdated = await ActivityService.showActivity(ganttTask.id);
                // updateActivitiesDictionary(activityUpdated.id, activityUpdated);
                // const customField = planningCustomFields.find((cf) => cf.id === customFieldId);
                // if (customField.type.choices[value]) {
                //     //     window.ganttInstance.updateTask(
                //     //         activityUpdated.id,
                //     //         formatGanttTask(activityUpdated, {
                //     //             color: value !== '' ? customField.type.choices[value] : null,
                //     //             durationApi: Number(activityUpdated.duration / durationUnit.duration),
                //     //         })
                //     //     );
                //     const isColorFiltered = tabPreferences.planning_color?.[planningSelected.id];
                //     if (isColorFiltered && isColorFiltered.name !== JOB_FIELD) {
                //         store.dispatch(incrementRefreshGantt());
                //     }
                // }
                // } else {
                //     window.ganttInstance.updateTask(
                //         activityUpdated.id,
                //         formatGanttTask(activityUpdated, {
                //             durationApi: Number(activityUpdated.duration / durationUnit.duration),
                //         })
                //     );
                // }
                return true;
                // window.ganttInstance.refreshData();
            } else {
                // const updatedData = {
                //     ...activityUpdated,
                //     startDate: moment(activityUpdated.startDate).utc(),
                //     endDate: moment(activityUpdated.endDate).utc(),
                // };
                // updateActivitiesDictionary(updatedData.id, updatedData);
                // // do not update parent in Gantt if activities grouped
                // if (isGroupement) {
                //     activityUpdated.activityParentId = ganttTask.parent;
                // }
                // if (isProject) {
                //     activityUpdated.activityParentId = 0;
                // }
                // window.ganttInstance.updateTask(
                //     activityUpdated.id,
                //     formatGanttTask(
                //         { ...activityUpdated },
                //         { durationApi: Number(activityUpdated.duration / durationUnit.duration) }
                //     )
                // );
                // window.ganttInstance.refreshData();
                // check if need to reget non working days
                // if isDateFiltered do not do reget
                await store.dispatch(refetchCalendars(activityUpdated));
            }
        } catch (error) {
            const durationUnit = timeUnits.find((timeUnit) => timeUnit.id === Number(ganttTask.dayDefinitionId));
            requestError(error, t('gantt_events.error_update_activity'));
            // Rollback changes
            const activityUpdated = await ActivityService.showActivity(ganttTask.id);
            const updatedData = clone(activityUpdated);
            // update parent with fake parentId
            store.dispatch(updateActivitiesDictionary(activityUpdated.id, activityUpdated));
            activityUpdated.activityParentId = ganttTask.parent;
            if (isProject) {
                activityUpdated.activityParentId = 0;
            }
            window.ganttInstance.updateTaskByServerId(
                activityUpdated.id,
                formatGanttTask(
                    { ...activityUpdated },
                    {
                        durationApi: Number(activityUpdated.duration / durationUnit.duration),
                        parentId: updatedData.activityParentId, // serverId of parent
                    }
                )
            );
            Sentry.captureException(error);
            return false;
        }
        // false because we don't need to update modif
        return true;
    });
    /* Ajustement de la hauteur des lignes du Gantt */
    window.ganttInstance.attachEvent('onBeforeRowResize', (task) => !task.isExtra);
    window.ganttInstance.attachEvent('onAfterRowResize', (id, item, oldHeight, newHeight) => {
        window.ganttInstance.config.row_height = newHeight;
        window.ganttInstance.config.bar_height = newHeight * 0.7;
        store.dispatch(
            updateTabPreferences({
                gantt_parameters: {
                    ...(tabPreferences.gantt_parameters ?? {}),
                    rowHeight: newHeight,
                },
            })
        );
        window.ganttInstance.batchUpdate(() => {
            const tasks = window.ganttInstance.getTaskByTime();
            tasks.forEach((task) => {
                if (!task.isExtra) {
                    // eslint-disable-next-line
                    task.row_height = newHeight;
                    // eslint-disable-next-line
                    task.bar_height = hasBaseLine ? (newHeight * 0.7) / 2 : newHeight * 0.7;
                    window.ganttInstance.updateTask(task.id);
                }
            });
        }, true);
    });
    // window.ganttInstance.attachEvent('onBeforeUndoStack', (action) => {
    //     console.log('🚀 ~ file: gantt_events.js ~ line 950 ~ window.ganttInstance.attachEvent ~ action', action);
    //     return true;
    // });
    window.ganttInstance.attachEvent('onAfterTaskAdd', (id, task) => {
        if (task.parent) {
            const parent = window.ganttInstance.getTask(task.parent);
            updateTaskType(task.parent, parent);
        }
    });
    window.ganttInstance.attachEvent('onAfterTaskDelete', (id, task) => {
        if (task.parent) {
            const parent = window.ganttInstance.getTask(task.parent);
            updateTaskType(task.parent, parent);
        }
    });

    window.ganttInstance.attachEvent('onAfterTaskUpdate', (id, task) => {
        updateTaskType(id, task);
    });

    window.ganttInstance.attachEvent('onLinkDblClick', () => canAccess);
    const computeChartDimensions = () => {
        // setting width for left part
        const chartOptionsElement = document.getElementById('gantt-histogram-options');
        const ganttGridContainer = document.getElementsByClassName('gantt_grid')[0];
        if (chartOptionsElement && ganttGridContainer) {
            chartOptionsElement.style.width = `${ganttGridContainer.offsetWidth}px`;
        }
        // setting width for right part
        const chartAreaElement = document.getElementById('gantt-histogram-area');
        const chartContainerElement = document.getElementById('gantt-histogram-container');
        const ganttChartContainer = document.getElementsByClassName('gantt_task')[0];
        const ganttChartDataArea = document.getElementsByClassName('gantt_data_area')[0];
        if (chartAreaElement && ganttChartContainer && chartContainerElement && ganttChartDataArea) {
            chartAreaElement.style.width = `${ganttChartContainer.offsetWidth}px`;
            chartContainerElement.style.width = `${ganttChartDataArea.offsetWidth}px`;
            // console.log("🚀 ~ file: gantt_events.js:1331 ~ computeChartDimensions ~ ganttChartDataArea.offsetWidth:", ganttChartDataArea.offsetWidth)
        }
    };
    const handleChartSyncScroll = () => {
        const ganttHorizontalScrollDiv = document.getElementsByClassName('gantt_hor_scroll')[0];
        const chartAreaElement = document.getElementById('gantt-histogram-area');

        if (ganttHorizontalScrollDiv && chartAreaElement) {
            ganttHorizontalScrollDiv.addEventListener('scrollend', () => {
                chartAreaElement.scrollLeft = ganttHorizontalScrollDiv.scrollLeft;
            });
            chartAreaElement.addEventListener('scrollend', () => {
                ganttHorizontalScrollDiv.scrollLeft = chartAreaElement.scrollLeft;
            });
            chartAreaElement.addEventListener('touchendend', () => {
                ganttHorizontalScrollDiv.scrollLeft = chartAreaElement.scrollLeft;
            });
        }
    };
    window.ganttInstance.attachEvent('onGanttRender', () => {
        computeChartDimensions();
        handleChartSyncScroll();
    });
    window.isComputingIndent = false;
    window.ganttInstance.attachEvent('onGanttScroll', () => {
        if (!window.isComputingIndent) {
            setTimeout(() => {
                window.isComputingIndent = true;
                window.ganttInstance.eachTask((task) => {
                    try {
                        if (task.isResource && task.parentType === 'task') {
                            const taskNode = document.querySelector(`.resource-task-row-${task.id}`);
                            if (taskNode) {
                                const taskTreeIndents = taskNode?.querySelectorAll('.gantt_tree_indent');
                                if (taskTreeIndents.length > 0) {
                                    // no background for last tree indent div
                                    const lastTreeIndent = taskTreeIndents[taskTreeIndents.length - 1];
                                    lastTreeIndent?.style.setProperty('background-color', 'transparent', 'important');
                                }
                            }
                        }
                    } catch (error) {
                        console.log('🚀 ~ file: gantt_events.js:1383 ~ window.ganttInstance.eachTask ~ error:', error);
                    }
                });
                window.isComputingIndent = false;
            }, 500);
        }
    });
    window.ganttInstance.attachEvent('onGridResizeEnd', computeChartDimensions);

    // CONTEXT MENU

    const handleParentTaskOpenStateWithLevel = (taskId, openState, level) => {
        const task = window.ganttInstance.getTask(taskId);
        const currentParentLevel = task.$level;
        const deltaLevel = level === 'all' ? 0 : +level - 1;
        const targetLevel = currentParentLevel + deltaLevel;
        // if same level, only open or close current task
        if (deltaLevel === 0) {
            if (openState) window.ganttInstance.open(taskId);
            else window.ganttInstance.close(taskId);
        }
        if (targetLevel !== currentParentLevel || level === 'all') {
            window.ganttInstance.batchUpdate(() =>
                window.ganttInstance.eachTask((childTask) => {
                    if (window.ganttInstance.hasChild(childTask.id) > 0) {
                        let condition = openState ? childTask.$level <= targetLevel : childTask.$level === targetLevel;
                        if (level === 'all') {
                            condition = true;
                        }
                        if (condition) {
                            if (openState) window.ganttInstance.open(childTask.id);
                            else window.ganttInstance.close(childTask.id);
                        }
                    }
                }, taskId)
            );
            window.ganttInstance.render();
        }
    };
    // USING DHTMLX CONTEXT MENU LIBRARY
    let contextMenuTaskId = null;
    /* eslint-disable-next-line */
    const parentTaskContextMenu = new dhtmlXMenuObject();
    parentTaskContextMenu.renderAsContextMenu();
    parentTaskContextMenu.setSkin('dhx_terrace');
    parentTaskContextMenu.loadStruct(PARENT_TASK_CONTEXT_MENU(i18n));
    parentTaskContextMenu.attachEvent('onClick', (id) => {
        const action = getExpandOrCollapseAction(id);
        handleParentTaskOpenStateWithLevel(contextMenuTaskId, action.open, action.level);
    });

    window.ganttInstance.attachEvent('onContextMenu', (taskId, linkId, event) => {
        const element = event.target;
        // We show context menu only if in grid area of gantt and if the task has children
        const isInGridTaskRow = element.closest('.gantt_row');
        if (isInGridTaskRow && window.ganttInstance.hasChild(taskId) > 0) {
            const x = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            const y = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            contextMenuTaskId = taskId;
            parentTaskContextMenu.showContextMenu(x, y);
            return false;
        }
        return true;
    });

    window.ganttInstance.attachEvent('onError', (errorMessage) => {
        console.error('DHTMLX error', errorMessage);
        return false;
    });
};
window.saveScrollState = true;
// saving scroll state
export const saveScrollState = async () => {
    if (window.saveScrollState && window.ganttInstance) {
        console.log('SAVING SCROLL STATE');
        const { x, y } = window.ganttInstance.getScrollState();
        const scrollState = { x, y };
        await store.dispatch(
            updateTabPreferences({
                gantt_scroll_state: scrollState,
            })
        );
    }
};
// restoring scroll state by preferences
export const restoreScrollState = (tabPreferences) => {
    if (tabPreferences?.gantt_scroll_state && window.saveScrollState && window.ganttInstance) {
        window.ganttInstance.scrollTo(tabPreferences.gantt_scroll_state.x, tabPreferences.gantt_scroll_state.y);
    }
};

export default { showTaskActions, addGanttEvents, updateParentState, saveScrollState, restoreScrollState };
